import { createContext, useState, useEffect } from "react";

export const FooterContext = createContext();

const FooterContextProvider = (props) => {
    const [footerData, setFooterData] = useState();

    useEffect(() => {
        fetch("https://ut-strapi-app.herokuapp.com/footer")
            .then(response => response.json())
            .then(result => setFooterData(result))
    }, [setFooterData]);

    return (
        <FooterContext.Provider value={{footerData}}>
            {props.children}
        </FooterContext.Provider>
    )
}

export default FooterContextProvider;