/** @jsxImportSource @emotion/react */
import { css } from "@emotion/react";

const Tour = ({ values, data }) => {
    const style = css`
        min-height: 103vh;
        padding: 2rem 2rem 2rem;
        text-align: center;
        color: black;

        .tour {

            &-headline {
                font-weight: normal;
                //color: ${values.activeColor};
                font-size: 2rem;
            }

            &-list {
                padding: 0;
                list-style-type: none;

                &-entry {
                    margin-top: 5rem;

                    &-link {

                        &-container {
                            background-color: transparent;

                            &_hidden {
                                display: none;
                            }

                            &:hover {
                                cursor: pointer;
                            }
                        }

                        &-btn {
                            padding: 0.3rem 1rem 0.1rem;
                            border-radius: 30px;
                            font-weight: bold;
                            font-family: "Poppins", -apple-system, BlinkMacSystemFont, "Segoe UI",
                                "Roboto", "Oxygen", "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans",
                                "Helvetica Neue", sans-serif;
                            background-color: transparent;
                            //border: solid 3px ${values.activeColor};
                            border: none;
                            border-bottom: solid 3px ${values.activeColor};
                            background-color: white;

                            &:hover {
                                    
                                cursor: pointer;
                            }   
                        }
                    }
                }
            }
        }

    `;

    return (
        <section css={style}>
            <h1 className="tour-headline">{data.headline}</h1>
            <ul className="tour-list">
                {data.tour_entry.map(tour => (
                    <li className="tour-list-entry">
                        <h2 className="tour-list-entry-headline">{tour.headline}</h2>
                        {tour.information.map(line => (
                            <p className="tour-list-entry-text">{line.text}</p>
                        ))}
                        <a className={tour.link_text === "hidden" ? "tour-list-entry-link-container tour-list-entry-link-container_hidden" : "tour-list-entry-link-container"} href={tour.link_text} target="_blank" rel="noreferrer">
                           <button className="tour-list-entry-link-btn">Tickets here</button> 
                        </a>
                    </li>
                ))}
            </ul>
        </section>
    );
}

export default Tour;