import { createContext, useState, useEffect } from "react";

export const PerformanceContext = createContext();

const PerformanceContextProvider = (props) => {
    const [performanceData, setPerformanceData] = useState();

    useEffect(() => {
        fetch("https://ut-strapi-app.herokuapp.com/performance")
            .then(response => response.json())
            .then(result => setPerformanceData(result))
    }, [setPerformanceData]);

    return (
        <PerformanceContext.Provider value={{performanceData}}>
            {props.children}
        </PerformanceContext.Provider>
    )
}

export default PerformanceContextProvider;