/** @jsxImportSource @emotion/react */
import { css } from "@emotion/react";

const GhostCoffee = ({ values, data }) => {
    const style = css`
        min-height: 103vh;
        padding: 2rem 2rem 2rem;

        .gc {

            &-headline {
                font-weight: normal;
                color: ${values.activeColor};
                font-size: 2rem;
            }

            &-img {
                object-fit: cover;
                width: 100%;
                height: 11rem;
                border-radius: 30px;

                &-collection {
                    margin-top: 3rem;
                    display: grid;
                    grid-template-columns: 1fr 1fr 1fr;
                    grid-gap: 2rem;

                    @media only screen and (max-width: ${values.breakpoint2}) {
                        grid-template-columns: 1fr 1fr;
                    }

                    @media only screen and (max-width: ${values.breakpoint4}) {
                        grid-template-columns: 1fr;
                    }
                }
            }

            &-link {
                border: none;
                background-color: transparent;

                &-btn {
                    padding: 0.3rem 1rem 0.1rem;
                    border-radius: 30px;
                    font-weight: bold;
                    font-family: "Poppins", -apple-system, BlinkMacSystemFont, "Segoe UI",
                        "Roboto", "Oxygen", "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans",
                        "Helvetica Neue", sans-serif;
                    background-color: transparent;
                    //border: solid 3px ${values.activeColor};
                    border: none;
                    border-bottom: solid 3px ${values.activeColor};
                    background-color: white;

                    &:hover {    
                        cursor: pointer;
                    }   
                }
            }
        }

    `;

    return (
        <section css={style}>
            <h1 className="gc-headline">{data.headline}</h1>
            <p className="gc-text">
                {data.text}
            </p>
            <a className="gc-link" href={data.btn_link} target="_blank" rel="noreferrer">
                <button className="gc-link-btn">See PDF here</button> 
            </a>
            <div className="gc-img-collection">
                {data.images.map(image => (
                    <img className="gc-img" src={image.url} alt={image.name}/>
                ))}
            </div>
        </section>
    );
}

export default GhostCoffee;