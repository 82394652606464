/** @jsxImportSource @emotion/react */
import { css } from "@emotion/react";

const Exhibition = ({ values, data }) => {
    const style = css`
        min-height: 103vh;
        padding: 2rem 2rem 2rem;

        .exhibition {

            &-headline {
                font-weight: normal;
                color: ${values.activeColor};
                font-size: 2rem;
            }

            &-img {
                object-fit: cover;
                width: 100%;
                height: 11rem;
                border-radius: 30px;

                &-collection {
                    margin-top: 3rem;
                    display: grid;
                    grid-template-columns: 1fr 1fr 1fr;
                    grid-gap: 2rem;

                    @media only screen and (max-width: ${values.breakpoint2}) {
                        grid-template-columns: 1fr 1fr;
                    }

                    @media only screen and (max-width: ${values.breakpoint4}) {
                        grid-template-columns: 1fr;
                    }
                }
            }
        }

    `;

    return (
        <section css={style}>
            <h1 className="exhibition-headline">{data.headline}</h1>
            <p className="exhibition-text">
                {data.text}
            </p>
        </section>
    );
}

export default Exhibition;