import { createContext, useState, useEffect } from "react";

export const AboutContext = createContext();

const AboutContextProvider = (props) => {
    const [aboutData, setAboutData] = useState();

    useEffect(() => {
        fetch("https://ut-strapi-app.herokuapp.com/about")
            .then(response => response.json())
            .then(result => setAboutData(result))
    }, [setAboutData]);

    return (
        <AboutContext.Provider value={{aboutData}}>
            {props.children}
        </AboutContext.Provider>
    )
}

export default AboutContextProvider;