import { createContext, useState, useEffect } from "react";

export const TourContext = createContext();

const TourContextProvider = (props) => {
    const [tourData, setTourData] = useState();

    useEffect(() => {
        fetch("https://ut-strapi-app.herokuapp.com/tour")
            .then(response => response.json())
            .then(result => setTourData(result))
    }, [setTourData]);

    return (
        <TourContext.Provider value={{tourData}}>
            {props.children}
        </TourContext.Provider>
    )
}

export default TourContextProvider;