import { createContext, useState, useEffect } from "react";

export const TopMenuContext = createContext();

const TopMenuContextProvider = (props) => {
    const [topMenuData, setTopMenuData] = useState();

    useEffect(() => {
        fetch("https://ut-strapi-app.herokuapp.com/top-menu")
            .then(response => response.json())
            .then(result => setTopMenuData(result))
    }, [setTopMenuData]);

    return (
        <TopMenuContext.Provider value={{topMenuData}}>
            {props.children}
        </TopMenuContext.Provider>
    )
}

export default TopMenuContextProvider;