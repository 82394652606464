/** @jsxImportSource @emotion/react */
import { css } from "@emotion/react";

const About = ({ values, data }) => {
    const style = css`
        min-height: 103vh;
        padding: 2rem 2rem 2rem;

        .about {

            &-headline {
                font-weight: normal;
                color: ${values.activeColor};
                font-size: 2rem;
            }

            &-sub-headline {
                font-weight: normal;
                //color: ${values.activeColor};

                &_hidden {
                    display: none;
                }
            }

            &-text {
                margin: 0;
            }
        }

    `;

    return (
        <section css={style}>
            <h1 className="about-headline">{data.headline}</h1>
            <p className="about-text">
            {data.first_paragraph}
            </p>
            <br></br><br></br>

            {data.paragraph.map(paragraph => (
                <>
                    <h2 className={paragraph.headline === "hidden" ? "about-sub-headline about-sub-headline_hidden" : "about-sub-headline"}>{paragraph.headline}</h2>
                    <p className="about-text">
                    {paragraph.text}
                    </p>
                    <br></br><br></br>
                </>
            ))}
        </section>
    );
}

export default About;