/** @jsxImportSource @emotion/react */
import { css } from "@emotion/react";

const People = ({ data, values }) => {
    
    const style = css`
        min-height: 103vh;
        padding: 3rem 3rem 2rem;

        .people {

            &-headline {
                font-weight: normal;
                margin: 1rem 0 4rem;
                text-align: center;
                font-size: 2rem;
            }

            &-list {
                list-style-type: none;
                padding: 0;
                display: grid;
                grid-template-columns: 1fr 1fr 1fr;
                grid-column-gap: 3rem;
                grid-row-gap: 4rem;

                @media only screen and (max-width: ${values.breakpoint2}) {
                    grid-template-columns: 1fr 1fr;
                }

                @media only screen and (max-width: ${values.breakpoint4}) {
                    grid-template-columns: 1fr;
                }

                &-entry {
                    padding: 1rem;
                    border-radius: 30px;

                    &-container {
                        text-decoration: none;
                        color: black;
                    }

                    &-img {
                        margin: 0 auto;
                        object-fit: cover;
                        object-position: center;
                        width: 10rem;
                        height: 12rem;
                        border-right: solid 3.2px ${values.activeColor};
                        border-bottom: solid 3.2px ${values.activeColor};
                        border-radius: 30px;
                        /*
                        border-bottom-right-radius: 30px;
                        border-top-left-radius: 30px;
                        border-top-right-radius: 20%;
                        border-bottom-left-radius: 20%;
                        */

                        &-container {
                        }
                    }

                    &-name {
                        font-size: 1rem;
                        margin: 0.5rem 0 0rem;
                        color: ${values.activeColor};   
                        text-align: center;
                    }

                    &-role {
                        font-size: 0.8rem;
                        text-align: center;
                        margin: 0;  
                    }

                    &:hover {
                        //background-color: rgba(150, 0, 0, 0.2);
                        //background-color: rgba(0, 0, 0, 0.1);
                    }
                }
            }
        }
    `;

    return (
        <section css={style}>
            <h1 className="people-headline">Discover the people involved:</h1>
            <ul className="people-list">
                {data.people.map((person, index) => (
                    <li className="people-list-entry" key={person.name}>
                        <a className="people-list-entry-container" href={`/people/${index}`}>
                            <div className="people-list-entry-img-container">
                                <img className="people-list-entry-img" src={person.image.url} alt={person.name}/>
                            </div>
                            <h2 className="people-list-entry-name" >{person.name}</h2>
                            <h3 className="people-list-entry-role" >{person.role}</h3>
                        </a>
                    </li>
                ))}
            </ul>
        </section>
    );
}

export default People;