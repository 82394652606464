/** @jsxImportSource @emotion/react */
import { css } from "@emotion/react";
// components //

const Contact = ({ data, values }) => {
    const style = css`
        min-height: 103vh;
        color: black;
        padding: 5rem 0 2rem;

        .section-container {
            max-width: 800px;
            margin: 0 auto;
        }

        .contact {

            &-headline {
                font-weight: 100;
                text-align: center;
                margin: 0 auto;
                font-size: 2rem;
            }

            &-list {
                padding: 0;
                width: fit-content;
                max-width: 500px;
                margin: 0 auto;
                font-size: 1.5rem;
                list-style-type: none;
            }

            &-entry {
                margin-top: 5rem;
                padding-left: 2rem;
                border-left: solid 3.2px ${values.activeColor};

                &__name, &__tlf, &__email {
                    font-weight: 100;

                    @media only screen and (max-width: ${values.breakpoint3}) {
                        font-size: 1.2rem;
                    }

                    @media only screen and (max-width: ${values.breakpoint4}) {
                        font-size: 1rem;
                    }
                }
            }
        }
    `;
    console.log(data.contact)

    return (
        <section css={style} id="contact-section">
            <div className="section-container">
                <h4 className="contact-headline">{data.contact[0].headline}</h4>
                <ul className="contact-list">
                    {data.contact[0].contact_entry.map(entry => (
                        <li className="contact-entry" key={entry.name}>
                            <h5 className="contact-entry__name">{entry.name}</h5>
                            <h5 className="contact-entry__tlf">{entry.phone_number}</h5>
                            <h5 className="contact-entry__email">{entry.email}</h5>
                        </li>
                    ))}
                </ul>
            </div>
        </section>
    )
}

export default Contact;