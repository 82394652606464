import { createContext, useState, useEffect } from "react";

export const ExhibitionContext = createContext();

const ExhibitionContextProvider = (props) => {
    const [exhibitionData, setExhibitionData] = useState();

    useEffect(() => {
        fetch("https://ut-strapi-app.herokuapp.com/exhibition")
            .then(response => response.json())
            .then(result => setExhibitionData(result))
    }, [setExhibitionData]);

    return (
        <ExhibitionContext.Provider value={{exhibitionData}}>
            {props.children}
        </ExhibitionContext.Provider>
    )
}

export default ExhibitionContextProvider;