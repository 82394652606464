/** @jsxImportSource @emotion/react */
import { css } from "@emotion/react";

const Performance = ({ values, data }) => {
    const style = css`
        min-height: 103vh;
        padding: 2rem 2rem 2rem;

        .performance {

            &-headline {
                font-weight: normal;
                color: ${values.activeColor};
                font-size: 2rem;
            }

            &-img {
                object-fit: cover;
                width: 100%;
                height: 11rem;
                border-radius: 30px;

                &-collection {
                    margin-top: 3rem;
                    display: grid;
                    grid-template-columns: 1fr 1fr 1fr;
                    grid-gap: 2rem;

                    @media only screen and (max-width: ${values.breakpoint2}) {
                        grid-template-columns: 1fr 1fr;
                    }

                    @media only screen and (max-width: ${values.breakpoint4}) {
                        grid-template-columns: 1fr;
                    }
                }
            }
        }

    `;

    return (
        <section css={style}>
            <h1 className="performance-headline">{data.headline}</h1>
            <p className="performance-text">
            {data.text}
            </p>
            <div className="performance-img-collection">
                {data.images.map(image => (
                    <img className="performance-img" src={image.url} alt=""/>
                ))}
            </div>
        </section>
    );
}

export default Performance;