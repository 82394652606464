/** @jsxImportSource @emotion/react */
import { css } from "@emotion/react";

import loaderImg from "../img/loader_icon.png";

const Loader = () => {

    const style = css`
        padding: 2rem;
        width: fit-content;
        margin: 0 auto;
        text-align: center;
        display: flex;
        align-items: center;
        flex-direction: column;

        .loader {

            &__img {
                margin-top: 10vh;
                width: 15rem;
                height: auto;
                margin-bottom: 2rem;

                @media only screen and (max-width: 825px) {
                    margin-top: 5vh;
                    width: ${window.matchMedia("(orientation: portrait)").matches ? "15rem" : "5rem"}
                }
            }

            &__text {
                font-size: 1.5rem;

            }

            &__sub-text {
                margin-top: 0;
                font-size: 1rem;
            }
        }


    `;
    

    return (
        <section css={style}>
            <img className="loader__img" src={loaderImg} alt="loader"/>
            <h2 className="loader__text">Loading data please wait...</h2>
            <h3 className="loader__sub-text">If the site hasn't been used recently this may take some time &#58;&#41;</h3>
        </section>
    );
}

export default Loader;