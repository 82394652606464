/** @jsxImportSource @emotion/react */
import { Video } from "cloudinary-react";

const VideoContainer = ({ className, videoName, thumbnail }) => {

    return (
        <Video poster={thumbnail} className={className} cloudName="duwgy5x4w" publicId={videoName} controls="true" >
        {/*<Transformation width="1920" height="1080" crop="pad"/>*/}
        </Video>
    );
}

export default VideoContainer;