/** @jsxImportSource @emotion/react */
import { css } from "@emotion/react";
import { useState } from "react";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faTimes } from '@fortawesome/free-solid-svg-icons'
import { faChevronRight } from '@fortawesome/free-solid-svg-icons'
import { faChevronLeft } from '@fortawesome/free-solid-svg-icons'

const Gallery = ({ values, data }) => {
    const [lightboxActive, setLightboxActive] = useState("none");
    const [currentImgIndex, setCurrentImgIndex] = useState(0);
    const [imgHover, setImgHover] = useState(0);
 
    const style = css`
        padding: 2rem 2rem 2rem;

        .gallery {

            &-lightbox {

                &-container {
                    overflow: hidden;
                    z-index: 99999;
                    top: 0;
                    right: 0;
                    background-color: rgba(0, 0, 0, 0.7);
                    display: ${lightboxActive};
                    position: fixed;
                    width: 100vw;
                    height: 100% ;
                    padding: 0;
                    align-items: center;
                }

                &-content {
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    width: 100%;
                }

                &-img {
                    width: 100%;
                    height: auto;
                    grid-column: 1/2;
                    grid-row: 1/3;

                    &-description {
                        grid-column: 1/2;
                        grid-row: 2/3;
                        background-color: rgba(0, 0, 0, 0.7);
                        color: white;
                        padding: 1rem;
                        margin: 0;
                        opacity: ${imgHover};
                        transition: 0.2s;
                    }

                    &-container {
                        grid-row: 1/2;
                        grid-column: 1/2;
                        display: grid;
                        max-width: 60vw;
                    }
                }

                &-exit-btn {
                    position: fixed;
                    top: 0;
                    right: 0;
                    margin-right: 1.5rem;
                    margin-top: 1rem;
                    width: fit-content;
                    height: auto;
                    font-size: 1.5rem;
                    padding: 0.3rem 0.8rem 0.1rem;
                    z-index: 9999999;
                    border-radius: 50px;
                    border: none;
                    font-weight: bold;
                    font-family: "Poppins", -apple-system, BlinkMacSystemFont, "Segoe UI",
                        "Roboto", "Oxygen", "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans",
                        "Helvetica Neue", sans-serif;
                    background-color: transparent;
                    //border: solid 3px ${values.activeColor};
                    border-bottom: solid 3px ${values.activeColor};
                    background-color: white;

                    &:hover {
                            
                        cursor: pointer;
                    }   
                }

                &-next-btn {
                    position: fixed;
                    right: 0;
                    margin-right: 1.5rem;
                    font-size: 3rem;
                    background-color: transparent;
                    border: none;
                    color: white;
                    opacity: 70%;
                    z-index: 9999999;

                    &:hover {
                        cursor: pointer;
                    }
                }

                &-prev-btn {
                    position: fixed;
                    left: 0;
                    margin-left: 1rem;
                    font-size: 3rem;
                    background-color: transparent;
                    border: none;
                    color: white;
                    opacity: 70%;
                    z-index: 9999999;

                    &:hover {
                        cursor: pointer;
                    }
                }
            }

            &-img {
                object-fit: cover;
                width: 100%;
                height: 11rem;
                border-radius: 30px;

                &:hover {
                    cursor: pointer;
                }

                &-collection {
                    margin-top: 3rem;
                    display: grid;
                    grid-template-columns: 1fr 1fr 1fr;
                    grid-gap: 2rem;

                    @media only screen and (max-width: ${values.breakpoint2}) {
                        grid-template-columns: 1fr 1fr;
                    }

                    @media only screen and (max-width: ${values.breakpoint4}) {
                        grid-template-columns: 1fr;
                    }
                }
            }
        }
    `;

    return (
        <section css={style}>
            <article className="gallery-lightbox-container">
            <div className="gallery-lightbox-content">
                <div className="gallery-lightbox-img-container">
                    <img className="gallery-lightbox-img" 
                        src={data.gallery_entry[currentImgIndex].entry_image.url} 
                        alt={data.gallery_entry[currentImgIndex].entry_image.name}
                        onMouseOver={()=> { setImgHover(100)}}
                        onMouseLeave={()=> { setImgHover(0)}}
                    />
                    <p className="gallery-lightbox-img-description">{data.gallery_entry[currentImgIndex].entry_description}</p>
                </div>
                <button className="gallery-lightbox-exit-btn" onClick={()=> { setLightboxActive("none") } }><FontAwesomeIcon icon={faTimes}/></button>
                <button className="gallery-lightbox-next-btn" onClick={()=> {
                    if (currentImgIndex === data.gallery_entry.length-1) {
                        setCurrentImgIndex(0);
                    } else {
                        setCurrentImgIndex(currentImgIndex + 1);
                    }
                }}><FontAwesomeIcon icon={faChevronRight}/></button>
                <button className="gallery-lightbox-prev-btn" onClick={()=> {
                    if (currentImgIndex === 0) {
                        setCurrentImgIndex(data.gallery_entry.length-1);
                    } else {
                        setCurrentImgIndex(currentImgIndex - 1);
                    }
                }}><FontAwesomeIcon icon={faChevronLeft}/></button>
            </div>
            </article>
            <div className="gallery-img-collection">
                {data.gallery_entry.map((entry, index) => (
                    <img className="gallery-img" src={entry.entry_image.url} alt={entry.entry_image.name} onClick={()=> { 
                        setLightboxActive("flex"); 
                        setCurrentImgIndex(index);
                    }}/>
                ))}
            </div>
        </section>
    );
}

export default Gallery;