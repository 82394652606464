/** @jsxImportSource @emotion/react */
import { css } from "@emotion/react";

import VideoContainer from "./VideoContainer";

const Descendants = ({ values, data }) => {
    const style = css`
        min-height: 103vh;
        padding: 2rem 2rem 0rem;

        .descendants {

            &-headline {
                font-weight: normal;
                color: ${values.activeColor};
                font-size: 2rem;
            }

            &-sub-headline {
                font-weight: normal;
                //color: ${values.activeColor};

                &_hidden {
                    display: none;
                }
            }

            &-text {
                margin-left: auto;
                margin-bottom: 2rem;
            }

            &__btn {
                margin: 0 0 1rem;
                border: none;
                background-color: transparent;
                text-align: left;
                padding: 0;
                padding-left: 0.3rem;

                @media only screen and (max-width: ${values.breakpoint4}) {
                    padding: 0;
                    margin: 0 auto 3rem;
                    text-align: center;
                    grid-column: 1/4;
                    grid-row: 3/4;
                }

                &_reverse {
                    grid-column: 2/4;
                    grid-row: 2/3;
                    text-align: right;
                    justify-self: right; 
                    padding-right: 0.3rem;

                    @media only screen and (max-width: ${values.breakpoint4}) {
                        padding: 0;
                        margin: 0 auto 3rem;
                        text-align: center;
                        grid-column: 1/4;
                        grid-row: 3/4;
                    }
                }
            }

            &__link {
                color: black;
                font-size: 1.2rem;
                font-family: "Poppins", -apple-system, BlinkMacSystemFont, "Segoe UI",
                    "Roboto", "Oxygen", "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans",
                    "Helvetica Neue", sans-serif;

                &:hover {
                    color: ${values.activeColor};
                    //opacity: 75%;
                }
            }

            &-countries-list {
                margin: 0;
                padding: 0;
                
                &-entry {
                    display: flex;
                    margin-bottom: 6rem;

                    @media only screen and (max-width: ${values.breakpoint3}) {
                        width: fit-content;
                        flex-direction: column;
                    }

                    &-img {
                        border-radius: 30px;
                        object-fit: cover;
                        width: 30rem;
                        height: 18rem;  
                        margin-right: 1rem;

                        @media only screen and (max-width: ${values.breakpoint2}) {
                            width: 28rem;
                            height: 16rem; 
                        }

                        @media only screen and (max-width: ${values.breakpoint3}) {
                            margin: 0 auto;
                            margin-bottom: 2rem; 
                        }

                        @media only screen and (max-width: ${values.breakpoint3}) {
                            width: 100%;
                            height: 10rem; 
                        }
                    }

                    &-video {
                        border-radius: 30px;
                        object-fit: cover;
                        width: 30rem;
                        height: 18rem;  
                        margin-right: 1rem;

                        @media only screen and (max-width: ${values.breakpoint2}) {
                            width: 28rem;
                            height: 16rem; 
                        }

                        @media only screen and (max-width: ${values.breakpoint3}) {
                            margin: 0 auto;
                            margin-bottom: 2rem; 
                        }

                        @media only screen and (max-width: ${values.breakpoint3}) {
                            width: 100%;
                            height: 10rem; 
                        }
                    }

                    &-info {
                        margin-left: auto;

                        @media only screen and (max-width: ${values.breakpoint3}) {
                            margin: 0 auto;
                        }

                        @media only screen and (max-width: ${values.breakpoint4}) {
                            padding-left: 0;
                        }

                        &__headline {
                            max-width: 15rem;
                            margin-left: auto;
                            margin-top: 0;
                            text-align: left;
                            font-weight: normal;

                            @media only screen and (max-width: ${values.breakpoint2}) {
                                text-align: center;
                                max-width: 100%;
                            }

                            @media only screen and (max-width: ${values.breakpoint3}) {
                                margin-right: auto;
                            }

                            @media only screen and (max-width: ${values.breakpoint4}) {
                                text-align: left;
                            }
                        }

                        &__text {
                            margin-left: auto;
                            max-width: 15rem;

                            @media only screen and (max-width: ${values.breakpoint3}) {
                                margin-left: 3rem;
                                max-width: 25rem;
                            }

                            @media only screen and (max-width: ${values.breakpoint4}) {
                                margin-right: auto;
                                margin-left: 0;
                                max-width: 100%;
                            }
                        }
                    }
                }
            }
        }

    `;

    return (
        <section css={style}>
            <h1 className="descendants-headline">{data.headline}</h1>
            <p className="descendants-text">
            {data.first_paragraph}
            </p>

            {data.paragraph.map(paragraph => (
                <>
                    <h2 className={paragraph.headline === "hidden" ? "descendants-sub-headline descendants-sub-headline_hidden" : "descendants-sub-headline"}>{paragraph.headline}</h2>
                    <p className="descendants-text">
                    {paragraph.text}
                    </p>
                </>
            ))}

            <button class="descendants__btn">
                <a 
                    href="https://vimeo.com/628966047/5baaaf3779?fbclid=IwAR2OThlAnuWUer2TcBjf2pYFDWcJLyZeuHMJw5NsmHOuuQn6WS48ieuCl9U" 
                    class="descendants__link"
                    target="_blank"
                    rel="noreferrer"
                >
                click here to watch trailer</a>
            </button>
            <br></br><br></br>

            <ul className="descendants-countries-list">
                {data.countries.map(country => (
                    <li className="descendants-countries-list-entry" key={country.name}>
                        {country.video !== null ? (
                            <VideoContainer videoName={country.video.hash} className={"descendants-countries-list-entry-video"} thumbnail={country.image[0].url}/>
                        ) : (
                            <img className="descendants-countries-list-entry-img" src={country.image[0].url} alt=""/>
                        )}
                        <div className="descendants-countries-list-entry-info">
                            <h2 className="descendants-countries-list-entry-info__headline">{country.name}</h2>
                            <p className="descendants-countries-list-entry-info__text">
                            {country.text}
                            </p>
                        </div>
                    </li>
                ))}
           </ul>
        </section>
    );
}

export default Descendants;