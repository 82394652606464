/** @jsxImportSource @emotion/react */
import { css } from "@emotion/react";
// components //

const Footer = ({ values, data }) => {
    
    const style = css`
        margin: 1rem auto 0;
        padding: 2rem 2rem 1rem;
        color: black;
        background-color: white;

        .copyright-text {
            align-self: center;
            flex: 7;
            font-weight: 100;
            font-size: 0.8rem;
            text-align: center;
            margin: 0.2rem 0 2rem 0;
        }

        .footer {

            &-content-container {
                display: flex;
                flex-direction: column;
            }

            &-logo-list {
                margin: 0;
                flex: 10;
                list-style-type: none;
                display: grid;
                grid-template-columns: 1fr 1fr 1fr 1fr 1fr 1fr;
                grid-column-gap: 1rem;
                grid-row-gap: 1.5rem;
                justify-content: center;

                @media only screen and (max-width: ${values.breakpoint2}) {
                    grid-template-columns: 1fr 1fr 1fr 1fr;
                    padding: 0;
                    margin: 0 auto;
                }

                @media only screen and (max-width: ${values.breakpoint4}) {
                    grid-template-columns: 1fr 1fr;
                    padding: 0 0 0 1rem;
                    margin: 0 auto;
                }

                &-entry {
                    display: flex;
                    align-items: center;

                    &-img {
                        object-fit: contain;
                        object-position: center;
                        width: 6rem;
                        height: 3rem;
                    }
                }
            }
        }
    `;

    return (
        data &&
        <footer css={style}>
            <div className="footer-content-container">
                <p className="copyright-text">{data.copyright_text}</p>
                <ul className="footer-logo-list">
                    {data.supporter.map(supporter => (
                        <li className="footer-logo-list-entry" key={supporter.id}>
                            <img className="footer-logo-list-entry-img" src={supporter.supporter_logo.url} alt={supporter.name}/>
                        </li>
                    ))}
                </ul>
            </div>
        </footer>
    )
}

export default Footer;