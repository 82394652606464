/** @jsxImportSource @emotion/react */
import { css } from "@emotion/react";
import { useState } from "react";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faBars } from '@fortawesome/free-solid-svg-icons'
// components //

const Menu = ({ data, values, currentPage }) => {
    const [hideMobileMenu, setHideMobileMenu] = useState(true);

    const style = css`
        z-index: 9999;
        position: sticky;
        width: 100%;
        top: 0px;
        //border-bottom: solid 3.5px #000;
        //border-top: solid 3.5px #000;
        //box-shadow: 0px 3px 0px #000;
        /*
        border-top: solid 3.5px #f75c35;
        border-bottom: solid 3.5px #f75c35;
        */
        //background-color: #2e2e2e;
        background-color: black;
        //background-color: white;
        //background-color: rgba(255,255,255, 0.8);
        list-style-type: none;

        .menu {
            margin: 0;
            padding: 0.8rem 1rem 0.8rem;
            display: flex;
            justify-content: center;
            flex-direction: row;
            list-style-type: none;
            z-index: 0;

            @media only screen and (max-width: ${values.breakpoint2}) {
                position: fixed;
                top: 0;
                left: 0;
                width: 100%;
                height: 100%;
                flex-direction: column;
                align-items: flex-start;
                justify-content: flex-start;
                padding-top: 5rem;
                background-color: black; 
                overflow: scroll; 
            }

            &-mobile {

                &-show-btn {
                    display: none;
                    z-index: 30000; 
                    margin: 0.8rem 0.6rem;
                    position: fixed;
                    top: 0;
                    right: 0;
                    border-radius: 30px;
                    font-weight: bold;
                    font-family: "Poppins", -apple-system, BlinkMacSystemFont, "Segoe UI",
                        "Roboto", "Oxygen", "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans",
                        "Helvetica Neue", sans-serif;
                    background-color: transparent;
                    //border: solid 3px ${values.activeColor};
                    border: none;
                    //border-bottom: solid 3px ${hideMobileMenu === true ? values.activeColor : "white"};
                    border-bottom: solid 3px ${values.activeColor};
                    font-size: 1.5rem;
                    color: ${hideMobileMenu === true ? "black" : "white"};
                    background-color: ${hideMobileMenu === true ? "white" : values.activeColor};
                    font-weight: normal;
                    padding: 0.5rem 1rem 0.2rem;

                    @media only screen and (max-width: ${values.breakpoint2}) {
                        display: block;
                    }
                }
            }

            &_hidden {
                display: flex;

                @media only screen and (max-width: ${values.breakpoint2}) {
                    display: none;
                }
            }

            &__item {
                margin: 0 1rem;

                @media only screen and (max-width: ${values.breakpoint1}) {
                    margin: 0 0.6rem;
                }

                @media only screen and (max-width: ${values.breakpoint2}) {
                    margin: 0 0 3rem 1rem;
                }

                &:last-child {
                    
                    @media only screen and (max-width: ${values.breakpoint2}) {
                        margin: 0 0 15rem 1rem;
                    }
                }

                &-link {
                    text-decoration: none;
                    //color: #f75c35;
                    color: white;
                    //color: black;
                    font-weight: 500;
                    opacity: 100%;

                    @media only screen and (max-width: ${values.breakpoint1}) {
                        font-size: 0.9rem;
                    }

                    @media only screen and (max-width: ${values.breakpoint2}) {
                        font-size: 1.5rem;
                    }

                    &_current {
                        border-bottom: solid 2px white;

                        &:hover {
                            color: white;
                        }
                    }

                    &:hover {
                        opacity: 75%;
                        //border-bottom: solid 2px white;
                        //color: ${values.activeColor};
                    }
                }
            }
        }
    `; 

    return (
       data &&
       <header css={style}>
           <button className="menu-mobile-show-btn" onClick={() => { setHideMobileMenu(!hideMobileMenu)}}>
               <FontAwesomeIcon icon={faBars}/>
           </button>
           <ul className={hideMobileMenu === true ? "menu menu_hidden" : "menu"}>
                {data.menu.menu_item.map( (entry) => (
                    <li className="menu__item" key={entry.name}>
                        <a className={entry.name === currentPage ? "menu__item-link menu__item-link_current" : "menu__item-link"} href={entry.name === "Home" ? "/" : `/${entry.name.toLowerCase()}`}>{entry.name}</a>
                    </li>
                ))}
                <li className="menu__item">
                    <a className={currentPage === "Contact" ? "menu__item-link menu__item-link_current" : "menu__item-link"} href="/contact">Contact</a>
                </li>
                {/*
                <li className="menu__item">
                    <a className="menu__item-link" href="/#contact-section">Contact</a>
                </li>
                */}
           </ul>
       </header> 
    )
}

export default Menu;