import './App.css';
import { Router } from "@reach/router";
//components//
import HomePage from "./views/HomePage";
import AboutPage from "./views/AboutPage";
import PeoplePage from "./views/PeoplePage";
import PersonPage from "./views/PersonPage";
import DescendantsPage from "./views/Descendants";
import GhostCoffeePage from "./views/GhostCoffeePage";
import GalleryPage from "./views/GalleryPage";
import PerformancePage from "./views/PerformancePage";
import TourPage from "./views/TourPage";
import ExhibitionPage from "./views/ExhibitionPage";
import ContactPage from "./views/ContactPage";

//contexts//
import HomepageContextProvider from "./contexts/HomepageContext";
import TopMenuContextProvider from "./contexts/TopMenuContext";
import FooterContextProvider from "./contexts/FooterContext";
import ValuesContextProvider from "./contexts/ValuesContext";
import AboutContextProvider from "./contexts/AboutContext";
import PeopleContextProvider from "./contexts/PeopleContext";
import DescendantsContextProvider from "./contexts/DescendantsContext";
import GhostCoffeeContextProvider from "./contexts/GhostCoffeeContext";
import GalleryContextProvider from "./contexts/GalleryContext";
import PerformanceContextProvider from "./contexts/PerformanceContext";
import TourContextProvider from "./contexts/TourContext";
import ExhibitionContextProvider from "./contexts/ExhibitionContext";
import ContactContextProvider from "./contexts/ContactContext";


function App() {
  return (
      <ValuesContextProvider>
      <TopMenuContextProvider>
      <FooterContextProvider>
      <HomepageContextProvider>
      <PeopleContextProvider>
      <AboutContextProvider>
      <DescendantsContextProvider>
      <GhostCoffeeContextProvider>
      <GalleryContextProvider>
      <PerformanceContextProvider>
      <TourContextProvider>
      <ExhibitionContextProvider>
      <ContactContextProvider>
        <div className="App">
          <Router>
            <HomePage path="/"/>
            <AboutPage path="/about"/>
            <PeoplePage path="/people"/>
            <PersonPage path="/people/:id" />
            <DescendantsPage path="/descendants"/>
            <GhostCoffeePage path="/ghost%20coffee"/>
            <GalleryPage path="/gallery"/>
            <PerformancePage path="/performance"/>
            <TourPage path="/tour"/>
            <ExhibitionPage path="/exhibition"/>
            <ContactPage path="/contact"/>
          </Router>
        </div>
      </ContactContextProvider>
      </ExhibitionContextProvider>
      </TourContextProvider>
      </PerformanceContextProvider>
      </GalleryContextProvider>
      </GhostCoffeeContextProvider>
      </DescendantsContextProvider>
      </AboutContextProvider>
      </PeopleContextProvider>
      </HomepageContextProvider>  
      </FooterContextProvider>
      </TopMenuContextProvider>
      </ValuesContextProvider>
  );
}

export default App;
