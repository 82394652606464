/** @jsxImportSource @emotion/react */
import { css } from "@emotion/react";
import { useContext } from "react";
// components //
import Menu from "../components/Menu";
import People from "../components/People";
import Footer from "../components/Footer";
import Loader from "../components/Loader";
//contexts//
import { PeopleContext } from "../contexts/PeopleContext";
import { TopMenuContext } from "../contexts/TopMenuContext";
import { FooterContext } from "../contexts/FooterContext";
import { ValuesContext } from "../contexts/ValuesContext";

const PeoplePage = () => {
    const { peopleData } = useContext(PeopleContext);
    const { topMenuData } = useContext(TopMenuContext);
    const { footerData } = useContext(FooterContext);
    const { valuesData } = useContext(ValuesContext);

    if (peopleData && valuesData) {
        var style = css`
        background-color: white;
        background-image: url(${peopleData.content_background.url});
        //background-size: 3000px;
        background-size: cover;
        background-repeat: no-repeat;
        background-position: center;

        .homepage-feed {

            &-wrapper {
                //margin-top: 5rem;
                //border-top: solid 30px ${valuesData.activeColor};
                background-color: rgba(255, 255, 255, 0.6);
                //background-color: ${valuesData.activeColor};
            }

            &-container {   
                max-width: 900px;
                margin: 0 auto;
                background-color: rgba(255, 255, 255, 0.5);
                //background-color: white;
            }
        }
    `; 
    }

    return (
        topMenuData 
        && peopleData 
        && footerData
        && valuesData ?
        <div css={style} className="wrapper">
            <Menu data={topMenuData} values={valuesData} currentPage="People"/>
            <section className="homepage-feed-wrapper" id="homepage-feed">
                <div className="homepage-feed-container">
                    <People data={peopleData} values={valuesData}/>
                    <Footer values={valuesData} data={footerData}/>
                </div>
            </section>
        </div>
        :
        <Loader />
    )
}

export default PeoplePage;