import { createContext, useState, useEffect } from "react";

export const GhostCoffeeContext = createContext();

const GhostCoffeeContextProvider = (props) => {
    const [ghostCoffeeData, setGhostCoffeeData] = useState();

    useEffect(() => {
        fetch("https://ut-strapi-app.herokuapp.com/ghost-coffee")
            .then(response => response.json())
            .then(result => setGhostCoffeeData(result))
    }, [setGhostCoffeeData]);

    return (
        <GhostCoffeeContext.Provider value={{ghostCoffeeData}}>
            {props.children}
        </GhostCoffeeContext.Provider>
    )
}

export default GhostCoffeeContextProvider;