import { createContext } from "react";

export const ValuesContext = createContext();

const ValuesContextProvider = (props) => {
    let valuesData = {
        pagePadding: "0.3rem",
        activeColor: "#a32121",
        breakpoint1: "965px",
        breakpoint2: "825px",
        breakpoint3: "720px",
        breakpoint4: "540px",
    }
    

    return (
        <ValuesContext.Provider value={{valuesData}}>
            {props.children}
        </ValuesContext.Provider>
    )
}

export default ValuesContextProvider;