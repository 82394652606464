/** @jsxImportSource @emotion/react */
import { css } from "@emotion/react";
// components //
//media//
import ArrowImg from "../img/frontpage_arrow.png";

const Hero = ({ data, values }) => {

    const style = css`
        height: 105vh;
        display: grid;
        color: white;
        background-image: url(${data.top_banner_image.formats.large.url});
        background-size: cover;
        background-position-x: center;

        @media only screen and (max-width: ${values.breakpoint2}) {
            height: 100vh;
        }

        @media only screen and (max-width: ${values.breakpoint4}) {
            height: 100vh;
            background-position-x: -320px;
        }

        .hero {

            &-filter {
                grid-column: 1/2;
                grid-row: 1/2;
                //background-image: linear-gradient(to bottom, rgb(162, 241, 255), 50%, rgba(0, 0, 0, 9));
                //background: linear-gradient(to bottom, rgb(255, 255, 255, 0) 40%, rgba(255, 255, 255, 0.7) 70%, 75%, #FFF);
                //background: linear-gradient(to bottom, rgb(255, 255, 255, 0) 40%, rgba(255, 255, 255, 0.7) 70%, 10%, #FFF);
                background-size: cover;
                opacity: 100%;
            }

            &-container {
                position: relative;
                grid-column: 1/2;
                grid-row: 1/2;
                margin: 0;
                padding: 11.5rem 0px 6rem;

                @media only screen and (max-width: ${values.breakpoint1}) {
                    padding: ${window.matchMedia("(orientation: portrait)").matches ? "11.5rem 0px 6rem" : "0px 0px 6rem"};
                }

                @media only screen and (max-width: ${values.breakpoint4}) {
                    padding: 0px 0px 6rem;
                }

            }

            &-headline {
                //border-top: solid 3.5px ${values.activeColor};
                //padding-top: 0.5rem;
                text-align: right;
                margin: 45vh 8.7rem 3rem 0;
                line-height: 1;
                font-size: 5rem;
                color: white;
                //color: #2c2c2c;
                //color: ${values.activeColor};
                text-shadow: 0px 3px 4px #000;
                //text-shadow: 4px 4px 4px #000, 7px 4px 2px ${values.activeColor};
                //text-shadow: 4px 4px 3px #000;
                //text-shadow: 0px 3px 4px ${values.activeColor};
                
                //text-shadow: 3px 3px 5px #000, 5px 5px 5px #000;

                @media only screen and (max-width: ${values.breakpoint2}) {
                    margin: 30vh 0 3rem 0;
                    text-align: center;
                    font-size: 4rem;
                }

                @media only screen and (max-width: ${values.breakpoint4}) {
                    margin: 20vh 0 40vh 1px;
                    font-size: 3.5rem;
                }
            }

            &-intro-text {
                display: none;
                text-align: center;
                width: 60%;
                color: black;
                //background-color: white;
                //box-shadow: 0px 0px 10px #000;
                //border: solid 2px black;
                padding: 0.5rem 1rem;
                font-size: 2rem;
                font-weight: bold;
                border-radius: 20px;
                border-left: solid 4px ${values.activeColor};
                border-right: solid 4px ${values.activeColor};
                border-bottom: solid 4px ${values.activeColor};
                margin: 0 auto 19vw;
            }

            &-arrow {

                &-container {
                    width: 3rem;

                    &:hover {
                        cursor: auto;
                    }
                }

                &-img {
                    display: block;
                    width: inherit;
                    margin: 0 46rem 0 auto;
                    filter: grayscale(100%);
                    filter: brightness(0%);
                    position: relative;
                    animation: arrowAnimation 1.5s infinite;
                    animation-timing-function: ease-in-out;
                    -webkit-animation-timing-function: ease-in-out;

                    @media only screen and (max-width: ${values.breakpoint2}) {
                        margin: 0 auto 0;
                    }

                    @media only screen and (max-width: ${values.breakpoint4}) {
                        width: 3rem;
                    }

                    &_boop {
                        transition: 1s;
                        transform: translate(0px, 15px)
                    }

                    &:hover {
                        cursor: pointer;
                        //filter: grayscale(0%);
                        filter: grayscale(100%);
                        filter: brightness(100%);

                        @media only screen and (max-width: ${values.breakpoint2}) {
                            cursor: pointer;
                            //filter: grayscale(0%);
                            filter: grayscale(0%);
                            filter: brightness(0%);
                        }
                    }
                }
            }
        }

        @keyframes arrowAnimation {
            0%   {top: 0px;}
            50%  {top: 30px;}
            100% {top: 0px;}
        }
    `; 

    return (
        data &&
        <section css={style}>
            <div className="hero-filter"></div>
                <article className="hero-container">
                    <h1 className="hero-headline">{data.frontpage_top_content.headline}</h1>
                    <h2 className="hero-intro-text">{data.frontpage_top_content.intro_text}</h2>
                    <a className="hero-arrow-container" href="/#homepage-feed">
                        <img className="hero-arrow-img" src={ArrowImg} alt="pil"/>
                    </a>
                </article>
        </section>
    )
}

export default Hero;