import { createContext, useState, useEffect } from "react";

export const ContactContext = createContext();

const ContactContextProvider = (props) => {
    const [contactData, setContactData] = useState();

    useEffect(() => {
        fetch("https://ut-strapi-app.herokuapp.com/contact")
            .then(response => response.json())
            .then(result => setContactData(result))
    }, [setContactData]);

    return (
        <ContactContext.Provider value={{contactData}}>
            {props.children}
        </ContactContext.Provider>
    )
}

export default ContactContextProvider;