import { createContext, useState, useEffect } from "react";

export const GalleryContext = createContext();

const GalleryContextProvider = (props) => {
    const [galleryData, setGalleryData] = useState();

    useEffect(() => {
        fetch("https://ut-strapi-app.herokuapp.com/gallery")
            .then(response => response.json())
            .then(result => setGalleryData(result))
    }, [setGalleryData]);

    return (
        <GalleryContext.Provider value={{galleryData}}>
            {props.children}
        </GalleryContext.Provider>
    )
}

export default GalleryContextProvider;