/** @jsxImportSource @emotion/react */
import { css } from "@emotion/react";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faChevronLeft } from '@fortawesome/free-solid-svg-icons'

const People = ({ data, values, personId }) => {
    
    const style = css`
        min-height: 103vh;
        padding: 3rem 3rem 2rem;

        @media only screen and (max-width: ${values.breakpoint4}) {
                   padding: 3rem 1rem 2rem;
        }

        .person {

            &-wrapper {
                display: grid;
                grid-column-gap: 1.5rem;
                padding: 1rem;
                border-radius: 30px;

                @media only screen and (max-width: ${values.breakpoint4}) {
                   padding: 0;
                }
            }

            &-img {
                margin: 0;
                object-fit: cover;
                width: 12rem;
                height: 14rem;
                border-right: solid 3.2px ${values.activeColor};
                border-bottom: solid 3.2px ${values.activeColor};
                border-radius: 30px;
                /*
                border-bottom-right-radius: 30px;
                border-top-left-radius: 30px;
                border-top-right-radius: 20%;
                border-bottom-left-radius: 20%;
                */

               @media only screen and (max-width: ${values.breakpoint4}) {
                    margin-right: auto;
                }

                &-container {
                    grid-column: 1/2;

                    @media only screen and (max-width: ${values.breakpoint4}) {
                        grid-column: 1/2;
                    }
                }
            }

            &-info {
                grid-column: 2/4;

                @media only screen and (max-width: ${values.breakpoint4}) {
                    grid-column: 1/2;
                }
            }

            &-name {
                font-size: 2rem;
                margin: 0.5rem 0 0rem;
                color: ${values.activeColor};   
                text-align: right;

                @media only screen and (max-width: ${values.breakpoint4}) {
                    text-align: left;
                }
            }

            &-role {
                font-size: 1.2rem;
                text-align: right;
                margin: 0.5rem 0 0;  

                @media only screen and (max-width: ${values.breakpoint4}) {
                    text-align: left;
                }
            }

            &-description {
                margin-top: 2rem;
                grid-column: 1/4;

                @media only screen and (max-width: ${values.breakpoint4}) {
                    grid-column: 1/2;
                }
            }

            &:hover {
                //background-color: rgba(150, 0, 0, 0.2);
                //background-color: rgba(0, 0, 0, 0.1);
            }

            &-back {

                &-link {
                    margin-right: auto;
                    margin-top: 2rem;
                    background-color: transparent;
                }

                &-btn {
                    min-width: 10rem;
                    border-radius: 30px;
                    font-weight: bold;
                    font-family: "Poppins", -apple-system, BlinkMacSystemFont, "Segoe UI",
                        "Roboto", "Oxygen", "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans",
                        "Helvetica Neue", sans-serif;
                    background-color: transparent;
                    //border: solid 3px ${values.activeColor};
                    border: none;
                    border-bottom: solid 3px ${values.activeColor};

                    @media only screen and (max-width: ${values.breakpoint4}) {
                        grid-column: 1/4;
                    }

                    &:hover {
                        cursor: pointer;
                    }
                }
            }
        }
    `;

    return (
        <section css={style}>
            <article className="person-wrapper">
                <div className="person-img-container">
                    <img className="person-img" src={data.people[personId].image.url} alt={data.people[personId].name}/>
                </div>
                <div className="person-info">
                    <h2 className="person-name">{data.people[personId].name}</h2>
                    <h3 className="person-role">{data.people[personId].role}</h3>
                </div>
                <p className="person-description">{data.people[personId].description}</p>
                <a className="person-back-link" href="/people">
                    <button className="person-back-btn"><FontAwesomeIcon icon={faChevronLeft}/> Go back to all people</button>
                </a>
            </article>
        </section>
    );
}

export default People;