/** @jsxImportSource @emotion/react */
import { css } from "@emotion/react";

const Headlines = ({ data, values }) => {
    const style = css`
        min-height: 103vh;
        padding: 1rem;
        //border-bottom: solid 30px ${values.activeColor};
        margin-bottom: 5rem;

        @media only screen and (max-width: ${values.breakpoint4}) {
            text-align: center;
            padding: 5rem 1rem 1rem;
        }

        .headline {
            padding-top: 6rem;
            max-width: 800px;
            margin: 0 auto;
            display: grid;

            @media only screen and (max-width: ${values.breakpoint4}) {
                padding-top: 3rem;
            }

            &__title {
                padding-right: 1rem;
                font-weight: normal;
                grid-column: 1/3;
                grid-row: 1/2;
                font-size: 2rem;
                margin: 1rem 0 1rem;
                color: black;
                border-bottom: 3.2px solid ${values.activeColor};

                @media only screen and (max-width: ${values.breakpoint4}) {
                    padding: 0;
                    grid-column: 1/4;
                    grid-row: 2/3;
                }

                &_reverse {
                    padding-left: 1rem;
                    grid-column: 2/4;
                    grid-row: 1/2;  
                    text-align: right;  

                    @media only screen and (max-width: ${values.breakpoint4}) {
                        padding: 0;
                        text-align: center;
                        grid-column: 1/4;
                        grid-row: 2/3;
                    }
                }
            }

            &__btn {
                grid-column: 1/3;
                grid-row: 2/3;
                width: 10rem;
                border: none;
                background-color: transparent;
                text-align: left;
                padding: 0;
                padding-left: 0.3rem;

                @media only screen and (max-width: ${values.breakpoint4}) {
                    padding: 0;
                    margin: 0 auto 3rem;
                    text-align: center;
                    grid-column: 1/4;
                    grid-row: 3/4;
                }

                &_reverse {
                    grid-column: 2/4;
                    grid-row: 2/3;
                    text-align: right;
                    justify-self: right; 
                    padding-right: 0.3rem;

                    @media only screen and (max-width: ${values.breakpoint4}) {
                        padding: 0;
                        margin: 0 auto 3rem;
                        text-align: center;
                        grid-column: 1/4;
                        grid-row: 3/4;
                    }
                }
            }

            &__link {
                color: black;
                font-size: 1.2rem;
                font-family: "Poppins", -apple-system, BlinkMacSystemFont, "Segoe UI",
                    "Roboto", "Oxygen", "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans",
                    "Helvetica Neue", sans-serif;

                &:hover {
                    color: ${values.activeColor};
                    //opacity: 75%;
                }
            }

            &__img {
                width: 100%;
                height: 22rem;
                object-fit: cover;
                grid-column: 3/4;
                grid-row: 1/4;
                border-right: solid 3.2px ${values.activeColor};
                border-bottom: solid 3.2px ${values.activeColor};
                border-radius: 30px;
                /*
                border-bottom-right-radius: 30px;
                border-top-left-radius: 30px;
                border-top-right-radius: 20%;
                border-bottom-left-radius: 20%;
                */

               @media only screen and (max-width: ${values.breakpoint4}) {
                   height: 15rem;
                   grid-column: 1/4;
                   grid-row: 1/2;
                }

                &_reverse {
                    grid-column: 1/2;
                    grid-row: 1/4;
                    border-left: solid 3.2px ${values.activeColor};
                    border-right: none;
                    border-radius: 30px;
                    /*
                    border-bottom-right-radius: 20%;
                    border-top-left-radius: 20%;
                    border-top-right-radius: 30px;
                    border-bottom-left-radius: 30px;
                    */

                    @media only screen and (max-width: ${values.breakpoint4}) {
                        grid-column: 1/4;
                        grid-row: 1/2;
                        border-left: none;
                        border-right: solid 3.2px ${values.activeColor};
                    }
                }
            }
        }

    `;

    return (
        data &&
        <section css={style}>
            {data.headline_section.map(headline => (
                <article key={`headline nr. ${headline.id}`} className="headline">
                    <h2 className={headline.id === 2 ? "headline__title headline__title_reverse" : "headline__title"}>{headline.title}</h2>
                    <button className={headline.id === 2 ? "headline__btn headline__btn_reverse" : "headline__btn"}><a className="headline__link" href={`/${headline.title.toLowerCase()}`}>read more</a></button>
                    <img className={headline.id === 2 ? "headline__img headline__img_reverse" : "headline__img"} src={headline.background_image.formats.small.url} alt={headline.title}/>
                </article>
            ))}
        </section>
    );
}

export default Headlines;