import { createContext, useState, useEffect } from "react";

export const DescendantsContext = createContext();

const DescendantsContextProvider = (props) => {
    const [descendantsData, setDescendantsData] = useState();

    useEffect(() => {
        fetch("https://ut-strapi-app.herokuapp.com/descendants")
            .then(response => response.json())
            .then(result => setDescendantsData(result))
    }, [setDescendantsData]);

    return (
        <DescendantsContext.Provider value={{descendantsData}}>
            {props.children}
        </DescendantsContext.Provider>
    )
}

export default DescendantsContextProvider;